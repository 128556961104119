import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from 'gatsby';
import Video from "../components/video";
export const data = graphql`
  query {
    file(relativePath: { eq: "chalk.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Img fluid={props.data.file.childImageSharp.fluid} alt="A stylized chalk banner that reads: Watchung Co-op." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <p><strong parentName="p">{`The Co-op's Discovery Pre-K class is currently full for school year 2020-2021. There is limited availability for Creative Play. Contact the school for more information.`}</strong>{` `}</p>
    <p>{`We encourage families considering our school to come visit us, take a firsthand look at our school community and discover the benefits of enrolling your child at the Co-op.`}</p>
    <p>{`If you would like to register for a tour, need to make other accommodations for touring the school, or would like more information about the Co-op, please `}<a parentName="p" {...{
        "href": "mailto:mary.szubiak@watchungco-op.org"
      }}>{`email our VP of Enrollment, Mary Szubiak`}</a>{`; or, call the Co-op directly at (973) 783-4535.`}</p>
    <hr></hr>
    <h2>{`The Co-op Experience: a Short Film`}</h2>
    <Video videoSrcURL="https://www.youtube-nocookie.com/embed/sX5nA3aUA7o" videoTitle="The Co-op Experience: a Short Film" mdxType="Video" />  
    <br />  
    <hr></hr>
    <h2>{`Parent Testimonials`}</h2>
    <p>{`Insert parent testimonials about the Co-op here, per suggestion during Board Meeting on November 11.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      